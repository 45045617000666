<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Input Surat Masuk Fisik</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table :items="items" :header="headers" :onDetail="doDetail" :onDelete="doDelete"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "../../../components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    return {
      headers: {
        name: {
          label: "Person last name",
          sortable: true
        },
        namaSingkat: {
          label: "Person first name",
          sortable: false
        },
        phone: {
          key: "age",
          label: "Person age",
          sortable: true
        },
        provinsi: {
          key: "address.city",
          label: "Person age",
          sortable: true
        }
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.suratFisik.items;
    },
    state() {
      return this.$store.state.suratFisik;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("suratFisik/STATE", state);
    this.get(state);
  },
  methods: {
    get() {
      this.$store.dispatch("suratFisik/getSuratFisik");
    },
    doDetail(val) {
      this.$store.dispatch("suratFisik/onDetail", val);
    },
    doDelete(val) {
      this.$store.dispatch("suratFisik/onDelete", val);
    }
  }
};
</script>
